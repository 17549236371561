import styled from '@emotion/styled';

const Title = styled('div')(({ theme }) => ({
  backgroundColor: theme.colors.gray['300'],
  height: '1.8rem',
  marginTop: '0.9rem',
  width: '50%',
}));

export default Title;
