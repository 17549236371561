import styled from '@emotion/styled';

const DummySubtitle = styled('div')(({ theme }) => ({
  backgroundColor: theme.colors.gray['300'],
  height: '1.6rem',
  marginTop: '0.5rem',
  width: '70%',
}));

export default DummySubtitle;
